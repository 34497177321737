import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    formData: {
      id: "",
      keyWord: ""
    },
    categoryId: "0"
  },
  mutations: {
    CHANGE_DATA: (state, payload) => {
      state.formData = payload
    },
    CHANGE_CATEGORY: (state, payload) => {
      state.categoryId = payload
    },
  },
  actions: {
    changeDate({ commit }, data) {
      commit('CHANGE_DATA', data)
    },
    changeCategory({ commit }, index) {
      commit('CHANGE_CATEGORY', index)
    },
  },
  modules: {
    user
  }
})
