import axios from 'axios'
import router from '@/router/index'
import store from '@/store'
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'

// console.log(process.env.VUE_APP_CONFIG_API,'!!!')
// create an axios instance
const service = axios.create({
  baseURL: '/prod-api/'
  // baseURL: '/api'
})
// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // console.log(error)
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code === 401) {
      //   if (!isRelogin.show) {
      //     isRelogin.show = true;
      //     MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
      //       isRelogin.show = false;
      //       useUserStore().logOut().then(() => {
      //         location.href = '/index';
      //       })
      //   }).catch(() => {
      //     isRelogin.show = false;
      //   });
      // }
      store.dispatch('logOut').then(res => {
        router.push("/")
      }).catch(err => {
        Message({ message: err, type: 'error' })
        return Promise.reject(new Error(err))
      })
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Message({ message: msg, type: error })
      return Promise.reject('error')
    } else {
      return Promise.resolve(res.data)
    }
  },
  error => {
    // console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)

export default service