export default {
  path: '/portal',
  component: () => import('@/views/service/index.vue'),
  // redirect: '/service/serviceHall',
  name: 'service',
  children: [
    {
      path: 'serviceHall',
      component: () => import('@/views/service/serviceHall'),
      name: 'serviceHall',
    },
    {
      path: 'serviceHallDetail',
      component: () => import('@/views/service/Detail'),
      name: 'serviceHallDetail',
    },
    {
      path: 'demandHall',
      component: () => import('@/views/service/DemandHall'),
      name: 'demandHall',
    },
    {
      path: 'demandHallDetail',
      component: () => import('@/views/service/DemandDetail'),
      name: 'demandHallDetail',
    },
    {
      path: 'home',
      component: () => import('@/views/service/Home'),
      name: 'home',
    },
    {
      path: 'agricultural',
      component: () => import('@/views/service/Agricultural'),
      name: 'agricultural',
    },
    {
      path: 'agriculturalDeatil',
      component: () => import('@/views/service/agriculturalDeatil'),
      name: 'agriculturalDeatil',
    },
    {
      path: 'organization',
      component: () => import('@/views/service/Organization'),
      name: 'organization',
    },
    {
      path: 'organizationDetail',
      component: () => import('@/views/service/OrganizationDetail'),
      name: 'organization',
    },
    {
      path: 'releaseRequirements',
      component: () => import('@/views/service/ReleaseRequirements'),
      name: 'releaseRequirements',
    },
    {
      path: 'publishingService',
      component: () => import('@/views/service/PublishingService'),
      name: 'publishingService',
    },
    {
      path: 'sciencePopularization',
      component: () => import('@/views/service/SciencePopularization'),
      name: 'sciencePopularization',
    },
    {
      path: 'sciencePopularizationDetail',
      component: () => import('@/views/service/SciencePopularizationDetail'),
      name: 'sciencePopularizationDetail',
    },
    {
      path: 'Disclaimers',
      component: () => import('@/views/service/Disclaimers'),
      name: 'Disclaimers',
    },
  ]
}