import Vue from 'vue'
import VueRouter from 'vue-router'
import service from './modules/service'

Vue.use(VueRouter)

const routes = [
  service,
  {
    path: '/',
    component: () => import('@/views/service/serviceHall'),
    redirect: '/portal/home',
  },
  {
    path: '/portal/register',
    component: () => import('../views/register'),
  },
  // {
  //   path: '/portal/imService',
  //   component: () => import('../views/imService'),  
  // },
  {
    path: '/portal/login',
    component: () => import('../views/login'),
  },
  {
    path: '/portal/flyOpretor',
    component: () => import('../views/flyOpretor'),
  },
  {
    path: '/portal/viewTerminal',
    component: () => import('../views/flyOpretor/viewTerminal'),
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
