import request from '@/utils/request'

//获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    method: 'get',
    timeout: 20000
  })
}

 //登录
export function loginInfo(username, password, code, uuid) {
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: { username, password, code, uuid }
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}