import { loginInfo, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import defAva from '@/assets/image/profile.jpg'
import { Message } from 'element-ui'

const state = {
  token: getToken(),
  name: '',
  userType: "",  //用户类型
  // avatar: '',
  roles: [],
  permissions: [],
  userId: "", //用户ID
}
const actions = {
  // 登录
  loginInfo({ commit }, userInfo) {
    const username = userInfo.username
    const password = userInfo.password
    const code = userInfo.code
    const uuid = userInfo.uuid
    return new Promise((resolve, reject) => {
      loginInfo(username, password, code, uuid).then(res => {
        if (res.code === 200) {
          let data = res.data
          setToken(data.access_token)
          commit('SET_TOKEN', data.access_token)
          Message({
            message: res.msg || '登录成功',
            type: 'success',
            duration: 2 * 1000
          })
        }

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户信息
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(res => {
        const user = res.user
        // const avatar = (user.avatar == "" || user.avatar == null) ? defAva : user.avatar;
        if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          commit('SET_USERINFO', res)
        } else {
          // this.roles = ['ROLE_DEFAULT']
        }
        // commit('SET_USERNAME', { name: user.userName, avatar: avatar })
        commit('SET_USERNAME', { name: user.userName, userType: user.userType, userId: user.userId })
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 退出系统
  logOut({ commit }) {
    return new Promise((resolve, reject) => {
      logout(this.token).then(() => {
        commit('CLEAR_DATA')
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const mutations = {
  SET_TOKEN: (state, payload) => {
    state.token = payload
    // console.log('SET_TOKEN', state.token);
  },
  CLEAR_DATA: (state) => {
    state.token = ""
    state.name = ""
    state.avatar = ""
    state.roles = []
    state.permissions = []
    // console.log('CLEAR_DATA', state);
  },
  SET_USERINFO: (state, payload) => {
    state.roles = payload.roles
    state.permissions = payload.permissions
    // console.log('SET_USERINFO', state.roles, state.permissions);
  },
  SET_USERNAME: (state, payload) => {
    state.name = payload.name
    state.userType = payload.userType
    state.userId = payload.userId
    // state.avatar = payload.avatar;
    // console.log('SET_USERNAME', state.name, state.userType)
  }
}


export default {
  state,
  actions,
  mutations
}